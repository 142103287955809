import React from 'react'
import { Link } from 'gatsby'
import { RichText, Date } from 'prismic-reactjs'
import linkResolver from '../../../utils/linkResolver'
import { Container, Section } from '../../../styles/common/Layout'
import { H1, P } from '../../../styles/common/Typo'
import tw from 'twin.macro'
import { Categories } from '../../Listing'

// Function to retrieve a small preview of the post's text
const firstParagraph = (post) => {
  // // Find the first text slice of post's body
  const firstTextSlice = post.body.find((slice) => slice.slice_type === 'text')
  if (firstTextSlice != null) {
    // Set the character limit for the text we'll show in the homepage
    const textLimit = 300
    const text = RichText.asText(firstTextSlice.primary.text.raw)
    const limitedText = text.substring(0, textLimit)

    if (text.length > textLimit) {
      // Cut only up to the last word and attach '...' for readability
      return (
        <p>{`${limitedText.substring(0, limitedText.lastIndexOf(' '))}...`}</p>
      )
    }
    // If it's shorter than the limit, just show it normally
    return <p>{text}</p>
  }
  // If there are no slices of type 'text', return nothing
  return null
}

// A summary of the Blog Post
const PostSummary = ({ post, id, index }) => {

  // Store and format the blog post's publication date
  let postDate = Date(post.node.data.date)
  postDate = postDate
    ? new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
    }).format(postDate)
    : ''

  // // Default title when post has no title set
  const defaultTitle = 'Untitled'

  let categories = false
  if (post.node.data.categories[0].category) {
    categories = post.node.data.categories.map(c => c.category.document.data.name)
  }

  return (
    <Section Color={index % 2 ? "" : "White"} key={id}>
      <Container>
        <StyledWrapper>
          <StyledSectionLeft>
            <P className="blog-post-meta">
              <time>{postDate}</time>
            </P>
            <H1 NoBottomMargin>
              <Link to={linkResolver(post.node)}>
                {RichText.asText(post.node.data.title.raw).length !== 0
                  ? RichText.asText(post.node.data.title.raw)
                  : defaultTitle}
              </Link>
            </H1>
            {categories && <Categories categories={categories} />}
          </StyledSectionLeft>
          <StyledSectionRight>
            {firstParagraph(post.node.data)}
          </StyledSectionRight>
        </StyledWrapper>
      </Container>
    </Section>
  )
}

export default ({ posts }) => {
  if (!posts) return null
  return (
    <>
      {posts.map((post, index) => (
        <PostSummary post={post} index={index} key={post.node.id} />
      ))}
    </>
  )
}

const StyledWrapper = tw.div`
  flex
  flex-col
  md:flex-row
`;

const StyledSectionLeft = tw.div`
  p-0
  pt-6
  w-full
  md:py-6
  md:w-1/2
  md:block
`;

const StyledSectionRight = tw.div`
  p-0
  w-full
  flex
  md:py-6
  md:w-1/2
`;