import React from 'react';
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";
import { RichText } from 'prismic-reactjs'
import { withPreview } from 'gatsby-source-prismic'
import { LocaleContext } from '../components/layout/layout';
import BlogPosts from '../components/feature/blog/blogPosts';
import { Container, Section } from '../styles/common/Layout';
import { A, H1, P } from '../styles/common/Typo';
import { SEO } from '../components';

// Query for the Blog Home content in Prismic
export const query = graphql`
  query MyQuery($locale: String!) {
    posts: allPrismicBlogPost(filter: { lang: { eq: $locale } },
      sort: { fields: data___date, order: DESC }
    ) {
      edges {
        node {
          url
          id
          uid
          type
          lang
          alternate_languages {
          lang
          type
          uid
          id
        }
          data {
            title {
              raw
            }
            date
            body {
              ... on PrismicBlogPostBodyText {
                id
                slice_label
                slice_type
                primary {
                  text {
                    raw
                  }
                }
              }
            }
            categories {
              category {
                document {
                  ... on PrismicCategory {
                    data {
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

// Using the queried Blog Home document data, we render the top section
// const BlogHomeHead = ({ home }) => {
//   const avatar = { backgroundImage: `url(${home.image.url})` }
//   return (
//     <div className="home-header container" data-wio-id={home.id}>
//       <div className="blog-avatar" style={avatar} />
//       <h1>{RichText.asText(home.headline)}</h1>
//       <p className="blog-description">{RichText.asText(home.description)}</p>
//     </div>
//   )
// }

const BlogPage = ({ data, pageContext: { locale }, location }) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  const url = location.href ? location.href : '';
  if (!data) return null

  // Define the Blog Home & Blog Post content returned from Prismic
  // const home = data.prismicBlog.data
  const posts = data.posts.edges

  return (
    <>
      <SEO
        title={`${i18n.pageBlog} | ${i18n.defaultTitleAlt}`}
        pathname={location.pathname}
        locale={locale} />
      <Section className="section__blog__intro">
        <Container>
          <H1>Let's read...</H1>
          <div data-name="locale-switcher">
            <strong>For my international visitors: </strong>
            <Link hrefLang="de-de" to="/blog">
              {i18n.langGerman}
            </Link>{' '}
            /{' '}
            <Link hrefLang="en-us" to="/en/blog">
              {i18n.langEnglish}
            </Link>
          </div>
        </Container>
      </Section>
      <BlogPosts posts={posts} />
    </>
  )
}

export default withPreview(BlogPage)

BlogPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}